<template>
    <v-container v-if="newsHome.length > 0" class="news_container">
        <div class="card_container">
            <card 
            :title="newsHome[0].title" 
            :category="newsHome[0].category" 
            :file="{}" 
            :id="newsHome[0].id" 
            :url="newsHome[0].url" 
            @open="openPost($event)" 
            :height="330"
            class="news_card"
            />
        </div>

        <v-divider vertical></v-divider>

        <div class="sideNews">
            <div class="d-flex align-center mb-5" style="cursor: pointer;" @click="openPost(newsHome[1].id)">
                <img :src="newsHome[1].url" :alt="newsHome[1].title" class="news_image">
                <div class="news_text">
                    <div class="category">
                        <div class="d-flex align-center">
                            <img src="../../../assets/check_mark_blue.svg" alt="Check Mark Azul" class="check_mark_blue">
                            {{ newsHome[1].category }}
                        </div>
                    </div>
                    <div class="news_header">
                        {{ newsHome[1].title }}
                    </div>
                    <div class="news_date">
                        <img src="../../../assets/calendar_icon.svg" alt="Icone de Calendário">
                        {{ newsHome[1].date }}
                    </div>
                </div>  
            </div>

            <div class="d-flex align-center mb-5" style="cursor: pointer;" @click="openPost(newsHome[2].id)">
                <img :src="newsHome[2].url" :alt="newsHome[2].title" class="news_image">
                <div class="news_text">
                    <div class="category">
                        <div class="d-flex align-center">
                            <img src="../../../assets/check_mark_blue.svg" alt="Check Mark Azul" class="check_mark_blue">
                            {{ newsHome[2].category }}
                        </div>
                    </div>
                    <div class="news_header">
                        {{ newsHome[2].title }}
                    </div>
                    <div class="news_date">
                        <img src="../../../assets/calendar_icon.svg" alt="Icone de Calendário">
                        {{ newsHome[2].date }}
                    </div>
                </div>  
            </div>

            <div class="d-flex align-center" style="cursor: pointer;" @click="openPost(newsHome[3].id)">
                <img :src="newsHome[3].url" :alt="newsHome[3].title" class="news_image">
                <div class="news_text">
                    <div class="category">
                        <div class="d-flex align-center">
                            <img src="../../../assets/check_mark_blue.svg" alt="Check Mark Azul" class="check_mark_blue">
                            {{ newsHome[3].category }}
                        </div>
                    </div>
                    <div class="news_header">
                        {{ newsHome[3].title }}
                    </div>
                    <div class="news_date">
                        <img src="../../../assets/calendar_icon.svg" alt="Icone de Calendário">
                        {{ newsHome[3].date }}
                    </div>
                </div>  
            </div>
        </div>

    </v-container>
</template>

<script>
import newsService from "../../../service/newsService";

export default {
  name: "NewsHome",
  methods: {
    openPost(id) {
      // this.$router.push({
      //   name: "Post",
      //   params: { id: id, text: "Notícia", href: "/news", type: "news" }
      // });

      this.$router.push({ path: `/post/news/${id}`})

    },
    getNews() {
      newsService.get({ spotlight: true, limit: 2 }).then(res => {
        const data = res.data;
        this.spotlight = data.map(el => {
           return {
              id: el.id,
              title: el.title,
              category: el.category,
              date: new Date(el.created_at).toLocaleString(),
              url: el.path_img_card
        };
        })
      });

      newsService.get({ limit: 7 }).then(res => {
        const news = res.data.map(card => {
          return {
            id: card.id,
            title: card.title,
            category: card.category,
            date: new Date(card.created_at).toLocaleString(),
            url: card.path_img_card
          };
        });

        news.forEach((newsCard, index) => {
          if (index <= 2) {
            this.slides.push(newsCard);
          } else if (index > 2 && index < 7) {
            this.newsHome.push(newsCard);
          }
        });
      });
    },

  },
  mounted() {
    this.getNews();
  },
  components: {
    Card: () => import("../../../components/commons/Card.vue"),
  },
  data() {
    return {
        slides: [],
        newsHome: [],
        spotlight: [{
            id: 0,
            title: "",
            category: "",
            date: "",
            url: ""
        },{
            id: 1,
            title: "",
            category: "",
            date: "",
            url: ""
        }]
    };
  }
};
</script>

<style lang="scss">
.news_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px auto;
}
.card_container {
    width: 50%;
    max-width: 600px;
        .v-card .data-card {
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
        }
    }
.sideNews {
    padding-right: 15px;
    max-height: 355px;
    overflow-y: scroll;
}
.news_image {
    width: 183px;
    height: 110px;
    border-radius: 4px;
    margin-right: 15px;
}
.category {
    display: inline-block;
    color: white;
    background-color: #1C8BE3;
    font-size: 13px;
    font-weight: 700;
    padding: 3px 9px;
    border-radius: 22px;
}
.check_mark_blue {
    margin-right: 6px;
}
.news_text {
    max-width: 235px;
}
.news_header {
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0px;
    text-transform: uppercase;
    color: #334666;
}
.news_date {
    display: flex;
    align-items: center;
    color: #334666;
    font-size: 14px;
    text-transform: uppercase;

    img {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #334666;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #26334a;
}
.theme--light.v-divider {
    border-color: #334666;

    @media screen and (max-width: 1150px){
        display: none;
    }
}

@media screen and (max-width: 970px) {
    .news_container {
        justify-content: center;
    }
    .card_container {
        width: 600px;
    }
    .sideNews, .news_text {
        max-width: 600px;
    }    
    .sideNews {
        margin-top: 30px;
    }
}   
</style>